import { Route, Routes } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "./component/sideBar";
import { useSelector } from "react-redux";
import ProtectedRouteAdminOnly from "./protectedRouteAdmin";

import OverView from "../overView";
import Dashboard from "../dashboard";
import Banners from "../banners";
import Categories from "../categories";
import SubCategories from "../subCategories";
import MenuConfig from "../menuConfig";
import Offers from "../offers";
import Settings from "../settings";
import Type from "../type";

import Products from "../products";
import AddProducts from "../products/addItem";
import Enquiry from "../enquiry";
import React, { useEffect } from "react";
import EditProducts from "../products/editItem";
import ManageStore from "../manageStore";
import Users from "../users";
import UserInfo from "../users/detailsFolder";
import Orders from "../orders";

import Sellers from "../sellers";
import SellerDetails from "../sellerDetails/detailPage";

import SellerRequestPage from "../sellerRequest";
import CoorporateSellerDetailsPage from "../sellerRequest/coorporate/detailPage";
import IndividualSellerDetailsPage from "../sellerRequest/individual/detailsPage";
import OrderDetailsPage from "../orders/orderDetailsPage";
import Invoices from "../invoice";
import CreateInvoice from "../invoice/createInvoice";
import POSScreen from "../pos";
import ViewInvoice from "../invoice/ViewInvoice";
import EditInvoice from "../invoice/editInvoice";

import LiveRate from "../liveRate";
import Blogs from "../blogs";
import SellUs from "../sellUs";
import InformationArea from "../informationArea";
import CategoryCards from "../categoryCards";
import CustomProduct from "../CustomeProduct";
import DetailedView from "../CustomeProduct/form";

function Admin() {
  const Role = useSelector((state: any) => state.User?.user?.data?.role ?? "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={2} style={{ margin: 0, padding: 0 }}>
            <div className="Screen-sideBox">
              <SideBar />
            </div>
          </Col>
          <Col sm={10} style={{ margin: 0, padding: 0 }}>
            <div className="Admin-box">
              <Routes>
                <Route
                  path="/"
                  element={
                    Role == "seller" ? (
                      <OverView />
                    ) : Role == "admin" ? (
                      <Dashboard />
                    ) : null
                  }
                />
                {/* admin only routes=================================================================== */}
                <Route element={<ProtectedRouteAdminOnly role={Role} />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/CustomProduct" element={<CustomProduct />} />
                  <Route
                    path="/CustomProduct/Details/:id"
                    element={<DetailedView />}
                  />
                  <Route path="/sub-categories" element={<SubCategories />} />
                  <Route
                    path="/information-area"
                    element={<InformationArea />}
                  />
                  <Route path="/category-cards" element={<CategoryCards />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/enquiry" element={<Enquiry />} />
                  <Route path="/Type" element={<Type />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/sellers" element={<Sellers />} />
                  <Route
                    path="/seller/coorporate/:id"
                    element={<CoorporateSellerDetailsPage />}
                  />
                  <Route
                    path="/seller/individual/:id"
                    element={<IndividualSellerDetailsPage />}
                  />
                  <Route path="/users/:_id" element={<UserInfo />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route
                    path="/invoices/createInvoice"
                    element={<CreateInvoice />}
                  />
                  <Route
                    path="/invoices/createInvoice"
                    element={<CreateInvoice />}
                  />
                  <Route
                    path="/invoices/editInvoice/:id"
                    element={<EditInvoice />}
                  />
                  <Route path="/invoice/:id" element={<ViewInvoice />} />
                  <Route path="/menu-config" element={<MenuConfig />} />
                </Route>
                <Route path="/overview" element={<OverView />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/manage_store" element={<ManageStore />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/sellus" element={<SellUs />} />
                <Route path="/seller/:id" element={<SellerDetails />} />
                <Route path="/seller_request" element={<SellerRequestPage />} />
                <Route path="/products" element={<Products />} />
                <Route path="/add-products" element={<AddProducts />} />
                <Route path="/edit-products/:id" element={<EditProducts />} />
                <Route path="/Orders" element={<Orders />} />
                <Route path="/Orders/:id" element={<OrderDetailsPage />} />
                <Route path="/pos" element={<POSScreen />} />

                <Route path="/liverate" element={<LiveRate />} />
              </Routes>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Admin;
