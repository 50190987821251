import React, { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

const ExchangePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text="Exchange Policy"></PageHeader>
        <h4 className="mt-4">Exchange Policy</h4>
        <div>
          At {API.NAME}, we understand that customer preferences may change over
          time. To facilitate a smooth exchange process, we have established the
          following exchange policy:
        </div>
        <h4 className="mt-4">Exchange within 14 Days of Purchase:</h4>
        <div>
          Customers can exchange any Gold Jewellery, Diamond and precious gem
          jewellery purchased from {API.WEBSITE} by returning the product to the
          registered address mentioned at {API.WEBSITE} Only.
        </div>
        <div className="my-2">
          The following conditions apply to Exchanges within 14 days of
          purchase:
        </div>
        <ul>
          <li>
            You can exchange your jewellery at full value, provided the
            jewellery is in its original condition.
          </li>
          <li>
            All exchange/buyback will be subject to a quality check by our
            Quality department or by a certified laboratory as required, which
            can take up to 4 working days.
          </li>
          <li>Exchange of ornaments in part is not allowed.</li>
          <li>
            The Exchange policy is not applicable to purchases made from any
            other online marketplace. For this purpose, one needs to follow the
            exchange policy of the respective channel.
          </li>
          <li>
            The exchanged product must be in its original condition, accompanied
            by the original invoice and any accompanying certificates or
            documents.
          </li>
          <li>
            Exchange facility without deducting making charge is available for
            the unused Gold/ Diamond Jewellery, within 14 days of purchase.
          </li>
          <li>
            {API.NAME} reserves the right to evaluate the product before
            confirming the exchange value.
          </li>
          <li>
            The exchange value will be adjusted against the new product selected
            by the customer. Any price difference between the original purchase
            and the new product must be paid by the customer.
          </li>
          <li>
            Exchange policies will not be applicable on promotional products and
            products sold on special offers.
          </li>
          <li>
            Custom-made or altered products, Smart Buy (Make-to-Order) orders,
            Rakhi and Gold Coins are not covered by the Exchange within 14 days
            policy. Should a customer desire to exchange these items, only the
            "Exchange after 14 days of purchase" and Buyback policies will be
            applicable
          </li>
          <li>
            The product must be returned to its original condition, along with
            the original invoice and any accompanying certificates or documents.
          </li>
          <li>
            Any gifts/gift voucher issued at the time of purchase should be
            returned at the time of exchange of the product or the value of the
            gift will be deducted accordingly.
          </li>
          <li>
            All taxes, duties, and fees as applicable based on the local law, to
            be borne by the customer.
          </li>
          <li>Only one such exchange will be permitted with an invoice</li>
        </ul>
        <h4 className="mt-4">Exchange After 14 Days of Purchase:</h4>
        <div>
          For exchanges requested after 14 days from the original purchase date,
          the exchange value will be determined based on the prevailing rates on{" "}
          {API.WEBSITE} or at the respective store at the time of exchange.
        </div>
        <div className="my-2">The exchange rates are as follows:</div>
        <ul>
          <li>Gold Jewellery: 100% of the benchmark gold rate.</li>
          <li>
            Diamond Jewellery: 100% of the benchmark gold rate and 100% of the
            prevailing diamond carat rate of the day after deducting the
            discount given (percentage) at the time of purchase.
          </li>
          <li>
            Era or Uncut Jewellery: 100% of the benchmark gold rate and 100% of
            the Invoice bill value of Uncut Diamond after deducting the discount
            at the time of purchase.
          </li>
          <li>
            Precia or Precious Stone Jewellery (Ruby, Emerald, Sapphire): 100%
            of the benchmark gold rate and 75% of the invoice value of precious
            stones after deducting the discount at the time of purchase.
          </li>
          <li>
            Solitaire Loose Diamonds: 100% of the prevailing diamond carat rate
            of the day after deducting the discount given (percentage) at the
            time of purchase.
          </li>
          <li>
            Solitaire Diamond Studded Jewellery: 100% of the benchmark gold rate
            and 100% of the prevailing diamond carat rate of the day after
            deducting the discount given (percentage) at the time of purchase.
          </li>
          <li>
            Platinum Jewellery: 100% of the benchmark platinum rate and 100% of
            the diamond value.
          </li>
          <li>Gold Coins: 100% of the benchmark gold rate.</li>
        </ul>
        <div className="my-2">The exchange rates are as follows:</div>
        <ul>
          <li>An original invoice and diamond certificate are required.</li>
          <li>
            Making charges, taxes, and stone weight (Synthetic/semi-precious
            colour stones, beads, pearls & zircon) will be completely deducted.
          </li>
          <li>
            Exchange of any category ornaments to Gold and Silver - Coin/Bar
            will not be allowed.
          </li>
          <li>
            If Era ornaments contain precious stones like ruby, emerald and blue
            sapphire, these stones must be buybacks as per the Precia buyback
            policy 75% for exchange and 70% for Cash.
          </li>
          <li>No Buyback for all pearls and synthetic stones</li>
          <li>
            All exchange/cash buyback will be subject to a quality check by our
            Quality department or by a certified laboratory as required, which
            can take up to 4 working days.
          </li>
          <li>
            {API.NAME} reserves the right to evaluate the product before
            confirming the exchange value.
          </li>
          <li>
            The exchange value will be adjusted against the new product selected
            by the customer. Any price difference between the original purchase
            and the new product must be paid by the customer.
          </li>
          <li>
            All taxes, duties and fees, as applicable based on the local law,
            are to be borne by the customer.
          </li>
        </ul>
        <h4 className="mt-4">Discretion of Rights</h4>
        <div>
          The company reserves the right to exercise discretion in permitting
          all exchanges, buy backs and the right to modify these terms and
          conditions without prior notice.
        </div>
        <div className="my-2">
          All disputes are subject to jurisdiction of the courts
        </div>
        <div>
          For any further assistance or clarification regarding our exchange
          policy, please reach out to our customer support team at{" "}
          {API.CONTACT_MAIL} or visit our website.
          <br />
          <br />
        </div>
      </div>{" "}
    </div>
  );
};

export default ExchangePolicy;
