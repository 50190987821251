import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

import API from "../../config/API";
import { GET } from "../../utils/apiCalls";

import Loader from "./components/laoder";
import Banners from "./components/banners";
import Offers from "./components/offers";
import PopularItems from "./components/popularItems";
import Loading from "../../components/loading";
import SubCategoryList from "./components/subCategoryList";
import SEO from "../../components/seo";
import React from "react";
import TopSellingStore from "./components/TopSellingStore";
import { LocationType } from "../../shared/types/types";
import Partners from "./components/partners";
import InformationArea from "./components/informationArea";
import { useTranslation } from "react-i18next";
import CategoryCard from "./components/categoryCard";
import GoldPrice from "./components/goldPrice";

function HomeSCreen() {
  const { t } = useTranslation();
  const Category = useSelector((state: any) => state.Category.categries);
  const Offerses = useSelector((state: any) => state.Category.offers);
  const [subCategories, setSubcategories] = useState<any[]>([]);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const Location = useSelector((state: any) => state.Location.location);
  const lattitude = Settings?.isLocation === true ? Location.latitude : "";
  const longitude = Settings?.isLocation === true ? Location.longitude : "";
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );

  const [Banner, setBanners] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [informationArea, setInformationArea] = useState([]);
  const [categorycards, setCategorycards] = useState([]);
  const getRecommendations = async () => {
    const url =
      API.PRODUCT_RECOMMENDATIONS +
      `order=ASC&price=ASC&lat=${lattitude}&long=${longitude}&take=20&radius=${Settings?.radius}`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRecommendations();
    getBanners();
    getBlogs();
    getInformationArea();
    getCategoryCards();
    window.scrollTo(0, 0);
  }, [lattitude]);

  const getAllSubcategories = async () => {
    const subcategories: any[] = [];
    const category_subcategory: string[] = [];
    Category?.forEach((item: any) => {
      category_subcategory.push(item.name);
      item?.sub_categories?.forEach((item: any) => {
        subcategories.push(item);
        category_subcategory.push(item.name);
      });
    });
    setSubcategories(subcategories);
  };

  const getBanners = async () => {
    const url =
      API.GET_HOMESCREEN +
      `lat=${lattitude}&long=${longitude}&radius=${Settings?.radius}`;
    try {
      const banners: any = await GET(url, null);
      if (banners.status) {
        setBanners(banners.data);
      }
    } catch (err) {
      console.log("failed to get banneers");
    }
  };

  useMemo(() => {
    getAllSubcategories();
  }, [Category]);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const getBlogs = async () => {
    try {
      let url = API.GET_LAST_3_BLOGS;
      let response: any = await GET(url, null);
      if (response.status) {
        setBlogs(response?.data);
      }
    } catch (err) {
      console.log("failed to get blogs");
    }
  };
  const getInformationArea = async () => {
    try {
      setLoading(true);
      let url = API.INFORMATIONAREA_LASTFIVE;
      let response: any = await GET(url, null);
      if (response.status) {
        setInformationArea(response?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getCategoryCards = async () => {
    try {
      setLoading(true);
      let url = API.CATEGORY_CARDS_LASTFIVE;
      let response: any = await GET(url, null);
      if (response.status) {
        setCategorycards(response?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <SEO
        title={API.NAME}
        description={`${API.NAME}! The leading powerhouse retailer of UAE`}
      />
      {Banner?.length ? <Banners data={Banner.slice(0, 3)} /> : null}
      <div className="Screen-box">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="HomeSCreen-space" />
            {subCategories?.length ? (
              <SubCategoryList data={subCategories} />
            ) : null}
            <GoldPrice />
            {Offerses?.length >= 2 ? (
              <Offers data={Offerses?.slice(0, 2)} />
            ) : null}
            <div className="HomeSCreen-space" />
            <InformationArea data={informationArea} />
            {LocationDetails.latitude &&
            LocationDetails.longitude &&
            Settings?.isLocation &&
            Settings?.type === "multi" ? (
              <>
                <TopSellingStore />
              </>
            ) : null}

            {loading ? (
              <Loading />
            ) : products.length > 11 ? (
              <>
                <PopularItems
                  type={2}
                  data={products?.slice(11, 20)}
                  title={t("top_products")}
                />
              </>
            ) : null}
            <div className="HomeSCreen-space" />
            <div className="HomeSCreen-space" />
            <CategoryCard data={categorycards} />
            {loading ? (
              <Loading />
            ) : products.length ? (
              <>
                <PopularItems
                  type={2}
                  data={products?.slice(0, 10)}
                  title={t("trending_now")}
                />
              </>
            ) : null}
            <div className="HomeSCreen-space" />
            <div className="HomeSCreen-space" />
            <Partners data={subCategories} />
            <div className="HomeSCreen-space" />
            {/* {loading ? <Loading /> : <BlogsAndNews blogs={blogs} />} */}
            <br />
          </>
        )}
        <br />
      </div>
    </div>
  );
}
export default HomeSCreen;
