import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { Col, Container, Row } from "react-bootstrap";
import { Skeleton } from "antd";
import "./styles.scss";
import { IoMailOutline } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import PageHeader from "../components/PageHeader";

function DetailedView() {
  const param = useParams();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoadin] = useState(true);
  useEffect(() => {
    LoadData(param?.id);
  }, []);

  const LoadData = async (val: any) => {
    try {
      let url = API.CUSTOMIZED_ORDER_ONE + `/${val}`;
      const data: any = await GET(url, null);
      if (data?.status) {
        setData(data?.data);
        setIsLoadin(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadin(false);
    }
  };
  return (
    <>
      <PageHeader title={`Order Details`} />
      <Container>
        <Row>
          <Col md={6}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <div className="customization-Box1">
                {data?.productIMage ? (
                  <img src={data?.productIMage} alt="description" />
                ) : null}
                <Row>
                  <div className="customization-Box1-Txt1">
                    Metal Details for{" "}
                    <strong style={{ color: "#2a5e47" }}>
                      {data?.productName}
                    </strong>
                  </div>
                  <Col md={6}>
                    <div className="Order-txt1">Metal Type :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.metal?.name}
                    </div>
                    <div className="Order-txt1">Purity :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.metal?.purity}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="Order-txt1">Metal Color :</div>
                    <div
                      style={{
                        backgroundColor: data?.config?.metal?.color,
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                      }}
                    ></div>
                    <div className="Order-txt1">Weight :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.metal?.weight}
                    </div>
                  </Col>
                </Row>
                {data?.config?.customName ? (
                  <>
                    <hr />

                    <Row>
                      <div className="customization-Box1-Txt1">
                        Custome Items
                      </div>
                      <Col md={6}>
                        <div className="Order-txt1">Name :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.customName?.name}
                        </div>
                        <div className="Order-txt1">Selected Chain :</div>
                        <div className="customization-Box1-Txt3">
                          <img src={data?.config?.selectedChain} />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="Order-txt1">Selected Font :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.selectedFont}
                        </div>
                        <div className="Order-txt1">
                          Example:
                          <br />
                          <span
                            style={{
                              fontFamily: data?.config?.selectedFont,
                              fontSize: "20px",
                              color: "#000",
                            }}
                          >
                            {data?.config?.customName?.name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}

                {data?.config?.selectedStones ? (
                  <>
                    <hr />

                    <Row>
                      <div className="customization-Box1-Txt1">
                        Stones Information
                      </div>
                      <Col md={6}>
                        <div className="Order-txt1">Position 1 :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.selectedStones?.position1Name
                            ? data?.config?.selectedStones?.position1Name
                            : "N/A"}
                        </div>
                        <div className="Order-txt1">Position 3 :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.selectedStones?.position3Name
                            ? data?.config?.selectedStones?.position3Name
                            : "N/A"}{" "}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="Order-txt1">Position 2 :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.selectedStones?.position2Name
                            ? data?.config?.selectedStones?.position2Name
                            : "N/A"}
                        </div>
                        <div className="Order-txt1">Position 4 :</div>
                        <div className="customization-Box1-Txt3">
                          {data?.config?.selectedStones?.position4Name
                            ? data?.config?.selectedStones?.position4Name
                            : "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}
                {/* <hr />
                <Row>
                  <div className="customization-Box1-Txt1">
                    Selected Sparkles
                  </div>
                  <Col md={6}>
                    <div className="Order-txt1">Postion 1 :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.selectedStones?.position1 ? (
                        <div className="App-Designbutton">
                          <div className="App-StonesBox">
                            <img
                              src={`../${data?.config?.selectedStones?.position1}`}
                            />
                          </div>
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div className="Order-txt1">Postion 3 :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.metal?.purity}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="Order-txt1">Postion 2 :</div>
                    <div
                      style={{
                        backgroundColor: data?.config?.metal?.color,
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                      }}
                    ></div>
                    <div className="Order-txt1">Postion 4 :</div>
                    <div className="customization-Box1-Txt3">
                      {data?.config?.metal?.weight}
                    </div>
                  </Col>
                </Row> */}
              </div>
            )}
          </Col>
          <Col md={6}>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <div className="customization-Box1">
                <div className="customization-Box1-Txt1">Pick up Details :</div>
                <div className="customization-Box1-Txt2">
                  Name :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerName}
                  </span>
                </div>
                <div className="customization-Box1-Txt2">
                  Mobile No :{" "}
                  <a
                    href={`tel:${data?.customerPhone}`}
                    className="customization-Box1-Txt4"
                  >
                    <IoIosCall /> &nbsp;
                    {data?.customerPhone}
                  </a>
                </div>
                <div className="customization-Box1-Txt2">
                  Email :{" "}
                  <a
                    href={`mailto:${data?.customerEmail}`}
                    className="customization-Box1-Txt4"
                  >
                    <IoMailOutline />
                    &nbsp; {data?.customerEmail}
                  </a>
                </div>
                <div className="customization-Box1-Txt2">
                  Address :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerAddress}
                  </span>
                </div>
                <div className="customization-Box1-Txt2">
                  Pincode :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerPin}
                  </span>
                </div>
                <div className="customization-Box1-Txt2">
                  Flat No :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerFlat}
                  </span>
                </div>
                <div className="customization-Box1-Txt2">
                  State :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerState}
                  </span>
                </div>
                <div className="customization-Box1-Txt2">
                  Country :{" "}
                  <span className="customization-Box1-Txt3">
                    {data?.customerCountry}
                  </span>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DetailedView;
