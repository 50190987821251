import React, { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

const BuyBackPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text="Buyback Policy"></PageHeader>
        <h4 className="mt-4">Buyback Policy</h4>
        <div className="pb-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, we offer a Cash buyback policy for Jewellery, solitaire
          diamonds, and gold coins/bars purchased from {API.WEBSITE} and any
          other Online marketplaces.
        </div>
        Please note the following details regarding our Buyback policy:
        <br />
        <br />
        <ul>
          <li>
            The buyback policy is applicable for Jewellery, solitaire diamonds,
            and gold coins after 14 days from the date of purchase.
          </li>
          <li>
            You can return your Gold jewellery, Gold coin/bar, Diamond/precious
            Gem jewellery at any {API.NAME} outlet with a valid invoice or by
            returning the product to the registered address mentioned at{" "}
            {API.WEBSITE}{" "}
          </li>
          <li>
            Standard rate deduction policy on the prevailing price of gold will
            be applicable on the gold component of your jewellery, gold
            coin/bar.
          </li>
          <li>
            The gold rate at our retail store or online at {API.WEBSITE} where
            the buyback occurs will be regarded as the benchmark gold rate.{" "}
          </li>
          <li>Gold Jewellery: 96% of the benchmark gold rate.</li>
          <li>
            Diamond Jewellery: 96% of the benchmark gold rate and 90% of the
            prevailing diamond carat rate of the day after deducting the
            discount given (percentage) at the time of purchase.
          </li>
          <li>
            Era or Uncut Jewellery: 96% of the benchmark gold rate and 80% of
            the Invoice bill value of Uncut Diamond after deducting the discount
            at the time of purchase.
          </li>
          <li>
            Precia or Precious Stone Jewellery (Ruby, Emerald, Sapphire): 96% of
            the benchmark gold rate and 70% of the invoice value of precious
            stones after deducting the discount at the time of purchase.
          </li>
          <li>
            Solitaire Loose Diamonds: 90% of the prevailing diamond carat rate
            of the day after deducting the discount given (percentage) at the
            time of purchase.
          </li>
          <li>
            Solitaire Diamond Studded Jewellery: 96% of the benchmark gold rate
            and 90% of the prevailing diamond carat rate of the day after
            deducting the discount given (percentage) at the time of purchase
          </li>
          <li>
            Platinum Jewellery: 97% of the benchmark Platinum rate and 90% of
            the prevailing diamond carat rate of the day after deducting the
            discount given (percentage) at the time of purchase.
          </li>
          <li>Gold Coins: 96% of the benchmark gold rate.</li>
          <li>
            In order to sell gold, the customer shall provide any identity
            proofs as follows:
          </li>
          <li>
            (a) Electoral ID (b) Aadhaar Card (c) Driving license (d) Passport
            (e) Pan Card
          </li>
        </ul>
        <h4 className="mt-4">Fair Value Assessment:</h4>
        Our buyback policy is designed to provide you with fair value for your
        Jewellery and solitaire diamonds. The prevailing gold and platinum price
        of our retail store will be applicable for the valuation of the product.
        Prevailing Price = Gross price less the % discount offered during
        purchase.
        <h4 className="mt-4">Approval and Evaluation:</h4>
        <div className="my-2">
          All exchange/buyback will be subject to a quality check by our Quality
          department or by a certified laboratory as required, which can take up
          to 4 working days.
        </div>
        <div className="my-2">
          {API.NAME} reserves the right to calculate the cash buyback amount
          based on the final evaluation conducted by our quality check experts.
          The evaluation considers various factors such as the condition,
          quality, and market value of the product.
        </div>
        <div className="my-2">
          Cash payments for buyback shall be made only by way of an account
          payee cheque or RTGS/NEFT, which may take 7-10 days and the same shall
          only be made in the name of the customer as per the invoice.
        </div>
        <div className="my-2">
          For exchange or buyback of custom made or altered products, only
          “Exchange after 14 days of purchase” & “Buyback” policies will be
          applicable.
        </div>
        <div className="my-2">
          Please note that the Buyback policy may vary for specific products or
          during promotional periods.
        </div>
        <div className="my-2">
          DISCRETION OF RIGHTS The company reserves the right to exercise
          discretion in permitting all exchanges, buybacks and the right to
          modify these terms and conditions without prior notice. All disputes
          are subject to the jurisdiction of the courts. For any further
          clarification or assistance regarding our cash buyback policy, please
          contact our customer support team at {API.CONTACT_MAIL} or visit our
          nearest store.
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default BuyBackPolicy;
