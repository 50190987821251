import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import { Button, notification } from "antd";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import DataTable from "./dataTable";
import useToggle from "../../shared/hook/useToggle";
import InformationModal from "./informationModal";
import AdminLoading from "../components/AdminLoading";
import Search from "antd/es/input/Search";
import UpdatePostionModal from "./updatePositionModal";

const InformationArea = () => {
  type ModalState = "add" | "update";
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [type, setType] = useState<ModalState>("add");
  const [toggle, toggleModal] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [reset, setReset] = useState(false);
  const [searching, setSearching] = useState(false);
  const [positionModal, togglePosition] = useToggle(false);
  const [informationArea, setInformationArea] = useState<any[]>([]);
  const [positionSelected, setPositionSelected] = useState<any>({});
  const pageSize = 10;
  useEffect(() => {
    getInformations();
  }, []);
  const getInformations = async (page: number = 1, search: string = "") => {
    setLoading(true);
    try {
      const url =
        API.INFORMATIONAREA +
        `?order=ASC&page=${page}&take=${pageSize}&search=${search}`;

      let response: any = await GET(url, null);
      if (response?.status) {
        setInformationArea(response?.data);
        setMeta(response?.meta);
      } else {
        notification.error({
          message: response?.message ?? "",
        });
      }
    } catch (err: any) {
      notification.error({
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };
  const changePage = async (page: number, take: number) => {
    await getInformations(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getInformations(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  return (
    <div>
      <PageHeader
        title={`Information Area ${loading ? "" : `(${meta.itemCount ?? 0})`}`}
        second={"Information Area"}
        secondPath={"/auth/information-area"}
      >
        <Button
          type="primary"
          onClick={() => {
            toggleModal(true);
            setType("add");
          }}
        >
          Add Information +
        </Button>
        <Search
          placeholder="Search on Title"
          allowClear={true}
          enterButton="Search"
          size="middle"
          loading={searching}
          onSearch={(value) => {
            if (!value) {
              getInformations();
              setReset(false);
              setPage(1);
              setSearch("");
            } else {
              onSearch(value);
            }
          }}
        />
      </PageHeader>
      {loading ? (
        <AdminLoading />
      ) : (
        <DataTable
          getInformations={getInformations}
          data={informationArea}
          openModal={() => toggleModal(true)}
          changeType={() => setType("update")}
          page={page}
          receiveSelectedItem={receiveSelectedItem}
          pageSize={pageSize}
          meta={meta}
          changePage={changePage}
          openPosition={() => togglePosition(true)}
          setPosition={setPositionSelected}
        />
      )}
      <InformationModal
        open={toggle}
        getInformations={getInformations}
        modalClose={() => toggleModal(false)}
        data={selectedItem}
        type={type}
      />
      <UpdatePostionModal
        open={positionModal}
        close={() => togglePosition(false)}
        data={positionSelected}
        refresh={() => getInformations(page, search)}
      />
    </div>
  );
};

export default InformationArea;
