import React, { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

const ShippingPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text="Shipping Policy"></PageHeader>
        <h4 className="mt-4">Shipping Policy</h4>
        <div>
          Shipping of the order from {API.WEBSITE} is very safe, secure, and
          hassle-free. All our products are insured during transit by an
          insurance company. Every package of the product (Jewellry / Gold Coin)
          purchased from {API.WEBSITE} will reach your doorstep in a durable
          condition and in tamper-proof packaging. Our courier partners include
          Blue Dart, Ecom Express, Malca Amit, and BVC and the shipping AWB
          number is provided to customers after we ship the order. The selection
          of the courier partner will depend on their serviceability to the
          shipping address.
        </div>
        <h4 className="mt-4">Shipping Charges:</h4>

        <div>
          We ship all orders through trusted courier partner and Shipping is
          FREE OF COST across all of India.
        </div>
        <h4 className="mt-4">Delivery to Identified Person:</h4>
        <div>
          Customers are required to provide accurate details of the
          consignee/recipient’s name (as stated in their government-issued photo
          identification) along with complete address, nearby landmark, pin
          code, and contact number for hassle-free delivery. At the time of
          delivery, the recipient will be required to provide any of the
          following identity proofs to collect the product:
        </div>
        <ul>
          <li>Passport</li>
          <li>PAN Card</li>
          <li>Driver's License</li>
          <li>Voter's ID</li>
          <li>Aadhar Card</li>
        </ul>
        <div>
          To ensure safe and secure delivery, the courier agent will verify the
          recipient's identity by checking the provided original copies of the
          identity proof. The courier agent will note down the details of the
          recipient's identity proof during the delivery process. Cooperation
          from the receiver is expected during this verification process.
        </div>
        <h4 className="mt-4">Delivery Location:</h4>
        <div className="my-2">
          Orders can only be delivered to the recipient's residential or work
          location or any nearby {API.NAME} showroom. Delivery to public places
          such as malls, hotels, restaurants, hostels, or on the street is not
          available.
        </div>
        <div>
          If the recipient is unavailable during the delivery attempts, the
          courier company will make three additional attempts to deliver the
          product. If the recipient is still unreachable or unavailable, the
          product will be returned to {API.NAME} facility.
        </div>
        <h4 className="mt-4">Change in Shipping Address:</h4>
        <ul>
          <li>
            Customers can track their orders by logging into their My Account
            section on the website {API.WEBSITE}
          </li>
          <li>
            Shipping details can be changed by the customer until the order is
            shipped from our facility. If the customer needs to change the
            shipping address, they should send an email to {API.CONTACT_MAIL}
          </li>
        </ul>
        <div className="my-2">
          The guarantee of product delivery is subject to the terms and
          conditions of the courier company. Any discrepancy in the recipient's
          details may result in non-delivery of the product.
        </div>
        <div>
          {API.WEBSITE} reserves the right to change this shipping policy at any
          time without prior notice. In the event of any changes, the revised
          policy will be posted on the website.
        </div>
        <h4 className="mt-4">Shipment Discrepancy:</h4>
        <div>
          Upon receiving your order, we advise you to carefully inspect the
          condition of the product. If you notice any damage, shortage, or
          over-shipment, or suspect that part of the shipment has been lost,
          please contact the {API.WEBSITE} Customer Care Department immediately
          (within 72 hours) by calling {API.CONTACT_NUMBER} and sending an
          official email to {API.CONTACT_MAIL}
        </div>
        <h4 className="mt-4">Damaged Products:</h4>
        <div>
          If you receive a damaged parcel or product, please contact the{" "}
          {API.WEBSITE} customer service department within 72 hours by calling{" "}
          {API.CONTACT_NUMBER} and sending an email to {API.CONTACT_MAIL}. Do
          not damage or discard the parcel or product. Our investigation team
          will investigate the complaint once we receive it by email and take
          appropriate action. Failure to report the incident within 72 hours of
          receiving the parcel will result in us not accepting responsibility
          for the damages, and no refund or complaint will be received.
        </div>
        <h4 className="mt-4">Missing Products:</h4>
        <div>
          We urge you to inspect the condition of the parcel before accepting it
          and report any issues within 72 hours of receiving the parcel. Please
          contact the {API.WEBSITE} customer service department by calling{" "}
          {API.CONTACT_NUMBER} and sending an email to {API.CONTACT_MAIL}. if
          you encounter any issues. Our investigation team will promptly address
          the matter and provide a resolution within 7 working days. Failure to
          report any issues within the specified time frame or failure to follow
          the recommended inspection process before accepting the parcel will
          result in us not being able to entertain any complaints.
        </div>
        <h4 className="mt-4">Non-Receipt of an Order:</h4>
        <div>
          At the time of dispatch, we provide the tracking ID of the shipment to
          the customer's registered email ID. It is the customer's
          responsibility to track the shipment and check for any updates. Once
          the shipment is delivered, we send a delivery confirmation to the
          registered email ID. If you do not physically receive the parcel after
          receiving our email, please contact our customer service department at{" "}
          {API.CONTACT_NUMBER} or send an email to {API.CONTACT_MAIL} within 72
          hours. Our investigation team will promptly address the issue and
          provide a resolution within 7 working days. However, please note that
          failure to report any issues within the specified time frame might
          result in us not being able to entertain any complaints. We will also
          not be able to issue any refunds to customers in case of any
          chargebacks raised to the bank without following due process as per
          the regulations thereof.
        </div>
        <h4 className="mt-4">Free Gift Packaging:</h4>
        <div>
          If the customer plans to purchase a gift, {API.NAME} provides
          complimentary gift packing along with a personalized gift message. The
          gift will be beautifully presented in special gift packaging. Whether
          the customer prefers to personally give the gift or have us deliver
          it, the recipient is certain to be delighted.
          <div className="my-2">
            To include a gift message with your purchase, simply locate the Gift
            message text box on the shopping bag/cart page. The custom message
            will be printed on a unique card and delivered alongside the gift
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
