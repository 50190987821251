import {
  Badge,
  Image,
  notification,
  Pagination,
  Popconfirm,
  Table,
} from "antd";
import React from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { BiSolidEditAlt } from "react-icons/bi";
import API from "../../config/API";
import { DELETE } from "../../utils/apiCalls";
import { FaList } from "react-icons/fa6";

const DataTable = (props: any) => {
  const renderEllipsisText = (text: string) => (
    <div
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
      }}
    >
      {text}
    </div>
  );
  const itemDeleteHandler = async (item: any) => {
    const url = API.CATEGORY_CARDS_MODIFY + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        notification.success({
          message: "Deleted",
          description: `Successfully Deleted ${item?.title}`,
        });
        props?.getCategoryCards();
      } else {
        notification.error({
          message: "Failed to Delete",
          description: response.message,
        });
      }
    } catch (err: any) {
      notification.error({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (data: any, record: any) => (
        <Image
          src={record?.image}
          width={50}
          height={50}
          style={{ borderRadius: "5%" }}
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      renderEllipsisText,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BiSolidEditAlt
            cursor="pointer"
            size={20}
            color="grey"
            onClick={() => {
              props?.changeType();
              props?.receiveSelectedItem(record);
              props?.openModal();
            }}
          />
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              itemDeleteHandler(record);
            }}
          >
            <AiTwotoneDelete cursor="pointer" size={20} color={API.COLOR} />
          </Popconfirm>
          <Badge count={record?.position} showZero size="small" color="orange">
            <FaList
              cursor="pointer"
              size={20}
              color={API.COLOR}
              onClick={() => {
                props?.openPosition();
                props?.setPosition(record);
              }}
            />
          </Badge>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={props?.data} pagination={false} />
      <div className="d-flex justify-content-end">
        <Pagination
          current={props?.page || 1}
          disabled={false}
          total={props?.meta?.itemCount}
          onChange={(current: any, pageSize: any) => {
            props?.changePange(current, pageSize);
          }}
        />
      </div>
    </div>
  );
};

export default DataTable;
