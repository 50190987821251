import { AutoComplete, Button, Form, Input, Tooltip, notification } from "antd";
import "./style.scss";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../shared/hook/useDebounce";
import { AUTO_COMPLETE, CURRENT_LOCATION } from "../../../utils/apiCalls";
import { IoSearch } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../../config/API";
import { currentLoctionType } from "../../../shared/types/types";
import Country from "../../../config/countryCode.json";

function AutoCompleteLocation(props: any) {
  const [options, setOptions] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState(
    props?.value ? props?.value : ""
  );
  const searchLocation = useDebounce(searchParam, 300);
  const [notificationApi, contextHolder2] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    locationAutoComplete(searchLocation);
  }, [searchLocation]);

  const getAutoComplete = (value: string) => {
    setSearchParam(value);
  };

  const getLocation = (option: { value: string; key: string }) => {
    try {
      const locationObj: any = {};
      locationObj.Place_id = option.key;
      const url =
        API.GET_LOCATION + `?place_id=${option.key}&key=${API.GGL_TOKEN}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .catch((err) => {
          notificationApi.error({ message: `Failed to get location` });
          return;
        })
        .then(async (response) => {
          if (response?.status == "OK") {
            getCurrentLocation(
              response.results[0]?.geometry?.location?.lat,
              response.results[0]?.geometry?.location?.lng
            );
          } else {
            notificationApi.error({
              message: `Request failed ,reason:${response.status}`,
            });
          }
        })
        .catch((err) => {
          notificationApi.error({ message: `Failed to get Location.` });
        });
    } catch (err) {}
  };

  const getSuggetions = (result: any) => {
    const array: any[] = [];
    result?.predictions?.forEach((item: any) => {
      array.push({
        value: item?.structured_formatting?.main_text,
        key: item?.place_id,
      });
    });
    setOptions(array);
  };

  const locationAutoComplete = async (value: string) => {
    try {
      if (value?.length > 2) {
        setIsLoading(true);
        const response: any = await AUTO_COMPLETE(value);
        if (response?.status == "OK") {
          getSuggetions(response);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      notificationApi.error({ message: `Failed to get Location details` });
    }
  };

  function handleLocationClick() {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocation, handleError);
    } else {
      setIsLoading(false);
      notificationApi.error({ message: `GeoLocation not supported` });
    }
  }
  const handleLocation = (position: any) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getCurrentLocationn(latitude, longitude);
  };

  const handleError = (error: any) => {
    setIsLoading(false);
    notificationApi.error({
      message: `Unable to get your Location. reason:${error.message}`,
    });
  };

  const getCurrentLocation = async (lat: number, long: number) => {
    try {
      const locationInfo: any = await CURRENT_LOCATION(lat, long);
      console.log("locationInfo====locationInfo=====<><><><>",locationInfo)
      if (locationInfo.status) {
        const locationData: currentLoctionType = locationInfo.data;
        console.log("locationData===========<<><><><><><===",locationData)
        const countryCode = Country.find(
          (item) => item.name === locationData?.country
        );
        let obj = {
          code: countryCode?.dial_code,
          pincode: locationData?.postal_code,
          state: locationData?.state,
          street: locationData?.subLocality,
          city: locationData?.taluk,
          address: locationData.street_address ?? locationData.premise,
          location: locationData?.subLocality,
          country:locationData?.country,
          lat: lat,
          long: long,
        };
        // props?.setCurrentLocation(obj);
        notificationApi.success({
          message: `Success`,
          description: `Address set to Your Current Location`,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationApi.error({ message: `Unable to get your Location.` });
      }
    } catch (err) {
      setIsLoading(false);
      notificationApi.error({ message: `Unable to get your Location.` });
    }
  };

  const getCurrentLocationn = async (lat: number, long: number) => {
    try {
      const locationInfo: any = await CURRENT_LOCATION(lat, long);
      console.log("locationInfo====locationInfo=====<><><><>",locationInfo)
      if (locationInfo.status) {
        const locationData: currentLoctionType = locationInfo.data;
        console.log("locationData===========<<><><><><><===",locationData)
        const countryCode = Country.find(
          (item) => item.name === locationData?.country
        );
        let obj = {
          code: countryCode?.dial_code,
          pincode: locationData?.postal_code,
          state: locationData?.state,
          street: locationData?.subLocality,
          city: locationData?.taluk,
          address: locationData.street_address ?? locationData.premise,
          location: locationData?.subLocality,
          country:locationData?.country,
          lat: lat,
          long: long,
        };
        props?.setCurrentLocation(obj);
        notificationApi.success({
          message: `Success`,
          description: `Address set to Your Current Location`,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationApi.error({ message: `Unable to get your Location.` });
      }
    } catch (err) {
      setIsLoading(false);
      notificationApi.error({ message: `Unable to get your Location.` });
    }
  };

  return (
    <div>
      {contextHolder2}
      <Form.Item
      noStyle
        label={props?.label ? "Location" : ""}
        name="location"
        rules={[
          {
            required: true,
            message: "Please Enter Location",
          },
        ]}
      >
        <AutoComplete
          options={options}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onChange={getAutoComplete}
          onSelect={(value, option) => getLocation(option)}
        >
          <Input
            placeholder="Search Location"
            size={props?.size}
            allowClear
            value={searchParam}
            prefix={<IoSearch/>}
            suffix={
              <Tooltip title="Set Your current Location">
                {isLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: 18, color: API.COLOR}}
                    spin
                  />
                ) : (
                  <Button size="small" type="primary"  onClick={() => handleLocationClick()}>My Location</Button>
                )}
              </Tooltip>
            }
          />
        </AutoComplete>
      </Form.Item>
    </div>
  );
}

export default AutoCompleteLocation;
