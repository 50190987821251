import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Input, Modal, notification } from "antd";
import { COMPRESS_IMAGE, POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";
import ImagePicker from "../components/ImagePicker";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const update = "update";
const InformationModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>({});
  const type = props?.type;
  const fileInputRef = useRef(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [alertVisible, setAlertVisible] = useState(true);
  const handleAlertClose = () => {
    setAlertVisible(false);
  };
  type OBJ = {
    image: string;
    title: string;
    subtitle: string;
    description: string;
  };
  useEffect(() => {
    if (type == update) {
      form.setFieldsValue({
        image: props?.data?.image,
        title: props?.data?.title,
        subtitle: props?.data?.subtitle,
        description: props?.data?.description,
      });
    } else {
      form.resetFields();
    }
  }, [props?.data, type]);
  const formSubmitHandler = async (values: OBJ) => {
    setIsLoading(true);
    try {
      let imageUrl = props?.data?.image;
      if (croppedImage) {
        const ImageBlob = await fetch(croppedImage).then((r) => r.blob());
        let name = moment(new Date()).unix();
        let file = new File([ImageBlob], name + "N.jpg");
        imageUrl = await COMPRESS_IMAGE(file);
      }
      const obj: OBJ = {
        image: imageUrl?.url ? imageUrl?.url : props?.data?.image,
        title: values?.title,
        subtitle: values?.subtitle,
        description: values?.description,
      };
      const information: any =
        type === update
          ? await PUT(API.INFORMATIONAREA_MODIFY + props?.data?.id, obj)
          : await POST(API.INFORMATIONAREA_CREATE, obj);
      if (information?.status) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${type == update ? "Updated" : "Added"}  ${
            information?.data?.title
          }`,
        });
        form.resetFields();
        props?.modalClose();
        props?.getInformations(props?.page);
        setImage({});
        setCroppedImage(null);
      } else {
        Notifications["error"]({
          message: `Failed to ${
            type == update ? "Update the Item" : "Add New Item"
          }`,
          description: information.message,
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: `Failed to ${
          type == update ? "Update the Item" : "Add New Item"
        }`,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas: HTMLCanvasElement | null =
        cropperRef.current.cropper.getCroppedCanvas({
          fillColor: "#fff",
        });
      if (canvas) {
        const croppedData = canvas.toDataURL("image/jpeg");
        setCroppedImage(croppedData);
        setCropModalOpen(false);
      }
    }
  };
  return (
    <Modal
      title={`${type == update ? "Update" : "Add New"} Information`}
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        setImage({});
        setCropModalOpen(false);
        setCroppedImage(null);
        props?.modalClose();
      }}
    >
      {contextHolder}
      {alertVisible && (
        <Alert
          className="small"
          message="Follow below guide before uploading images"
          description={
            <ul>
              <li>
                Use images with light background to highlight the text
                description and title.
              </li>
              <li>
                Informtion Area images are cropped to 1 : 0.72 ratio for better
                visibility.
              </li>
              <li>
                Preferred image formats are JPEG, JPG, or PNG and avoid file
                sizes bigger than 5MB.
              </li>
            </ul>
          }
          type="warning"
          closable
          onClose={handleAlertClose}
        />
      )}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
      >
        {cropModalOpen ? (
          <div className="mt-2">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none", backgroundColor: "grey" }}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setImage({
                    file: selectedFile,
                    url: URL.createObjectURL(selectedFile),
                  });
                  setCropModalOpen(true);
                }
              }}
            />
            <Cropper
              ref={cropperRef as React.RefObject<ReactCropperElement>}
              src={image?.url}
              style={{ height: 400, width: "100%" }}
              guides={true}
            />
            <div
              style={{ textAlign: "center", marginTop: 16 }}
              className="d-flex justify-content-evenly"
            >
              <Button
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as any).click();
                  }
                }}
              >
                Choose Another Image
              </Button>
              <Button type="primary" onClick={handleCrop}>
                Crop Image
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Form.Item
              label="Image"
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please Add Image",
                },
              ]}
            >
              <div className=" d-flex justify-content-center">
                <ImagePicker
                  size="categoryCards"
                  onChange={(file: any) => {
                    setImage(file);
                    setCropModalOpen(true);
                  }}
                  fileURL={
                    image.file || croppedImage
                      ? croppedImage || image.url
                      : type == update
                      ? props?.data?.image
                      : null
                  }
                />
              </div>
            </Form.Item>
          </>
        )}
        <Form.Item
          label="Title"
          name={"title"}
          rules={[
            {
              required: true,
              message: "Please Enter a title",
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Subtitle"
          name={"subtitle"}
          rules={[
            {
              required: true,
              message: "Please Enter subtitle",
            },
          ]}
        >
          <Input placeholder="Subtitle" />
        </Form.Item>

        <Form.Item
          label="Description"
          name={"description"}
          rules={[
            {
              required: true,
              message: "Please Enter Description",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Description" />
        </Form.Item>

        {cropModalOpen ? null : (
          <div className="d-flex gap-2 justify-content-end">
            <Button
              onClick={() => {
                setImage({});
                setCroppedImage(null);
                setCropModalOpen(false);
                props?.modalClose();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" loading={isLoading} onClick={form.submit}>
              {type == "add" ? "Add" : "Update"}
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default InformationModal;
