import React, { useContext, useMemo, useState } from "react";
import { Button, notification } from "antd";
import { BsHandbag } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidShareAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import OrderBulkModal from "./modal/bulkOrderModal";
import useToggle from "../../../shared/hook/useToggle";
import { storeCheckout } from "../../../redux/slices/checkoutSlice";
import { SingleProductContext } from "../singleProductContext";
import { FaHeart, FaMinus, FaPlus } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";

const CartCard = () => {
  const data = useContext(SingleProductContext);
  const productInfo = data?.data;
  const addToCart = data?.addtoCart;
  const activeVariant = data?.activeVariant;
  const loadingCart = data?.loadingCart;
  const addWishlist = data?.addWishlist;
  const [quantity, setQuantity] = useState(1);
  const [openModal, toggleModal] = useToggle(false);
  const availableQuantity = activeVariant?.variant?.units ?? productInfo.unit;
  const [notificationApi, contextHolder] = notification.useNotification();
  const liverate = useSelector((state: any) => state.Liverate.liverate);



const shareLink = async () => {
  try {
    if (navigator?.share) {
      await navigator.share({
        title: document?.title,
        url: window?.location?.href,
      });
    } else {
      notification.error({ message: `Failed to share link` });
    }
  } catch (err) {
    // notification.error({ message: `Failed to share link` });
  }
};






  const calculatePrice = () => {
    try {
      let method = productInfo?.price_method;
      let metal_type = productInfo?.metal_type;
      let weight = Number(productInfo?.weight);
      let vat = Number(productInfo?.vat);
      let labour_charge = Number(productInfo?.labour_charge);
      if (method === "Live") {
        const rate = liverate.length
          ? liverate.filter((i: any) => i.metal_type === metal_type)
          : [];
        if (rate?.length) {
          let price = Number(rate[0].ask);
          let amount = Number(price) * Number(weight);
          const labourCharge = Number(amount / 100) * labour_charge;
          let grand = Number(amount) + Number(labourCharge);
          let vatAmount = (grand * vat) / 100;
          let totalAmount = Number(grand) + Number(vatAmount);
          return totalAmount.toFixed(2);
        } else {
          return 0;
        }
      } else {
        return data?.data?.purchase_rate;
      }
    } catch (err) {
      return 0;
    }
  };
  const buyNow = () => {
    if (productInfo?.status != true) {
      notification.error({ message: `Product is Temporarily not Available` });
      return;
    } else if (productInfo.unit == 0) {
      notification.error({ message: `Product is Out of Stock!!` });
      return;
    } else if (quantity > productInfo.unit) {
      notification.error({ message: `Selected Quantity is Not Available.` });
      return;
    }
    if (activeVariant?.variant?.id) {
      if (activeVariant?.variant?.units == 0) {
        notification.error({ message: `This Variant is out of stock` });
        return;
      } else if (quantity > activeVariant?.variant?.units) {
        notification.error({
          message: `Selected Quantity is Not Available for this Variant`,
        });
        return;
      }
    }

    const obj = {
      name: productInfo?.name,
      buyPrice: calculatePrice(),
      productId: productInfo?._id,
      quantity: quantity,
      storeId: productInfo?.store_id,
      totalPrice: Number(calculatePrice()) * quantity,
      userId: User.data?._id,
      variantId: activeVariant?.variant?.id ?? null,
      image: activeVariant?.variant?.id
        ? activeVariant?.variant?.image
        : productInfo?.image,
      combination: activeVariant?.variant?.combination,
      storeName: productInfo?.storeDetails?.store_name,
    };
    dispatch(storeCheckout([obj]));
    navigate("/checkout");
  };
  const handleIncrement: any = () => {
    setQuantity((quanti) => {
      if (
        productInfo.unit <= quantity ||
        activeVariant?.variant?.units <= quantity
      ) {
        return quanti;
      }
      return quanti + 1;
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getQuantity = (quantity: number) => {
    setQuantity(quantity);
  };
  const Auth = useSelector((state: any) => state.User);
  const User = useSelector((state: any) => state.User.user);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigation = useNavigate();
  const handleDecrement: any = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const VatPercent = useMemo(() => {
    if (isNaN(Number(productInfo?.vat)) == false) {
      return Number(productInfo?.vat);
    }
    return null;
  }, [productInfo?.vat]);
  return (
    <div className="productDetails-cart-card">
      {contextHolder}
      {productInfo.unit == 0 || activeVariant?.variant?.units == 0 ? (
        <h5 className="text-danger">Currently Out of Stock</h5>
      ) : productInfo.unit < quantity ||
        activeVariant?.variant?.units < quantity ? (
        <h5 className="text-danger">{`Only ${availableQuantity} units left`}</h5>
      ) : (
        <div className="productDetails-txt1">Available to Order</div>
      )}
      <div className="productDetails-txt2">
        Fully insured delivery on all orders
      </div>

      {VatPercent ? (
        <div className="productDetails-txt3 text-dark fw-bold">
          Vat Inclusive Rate
        </div>
      ) : null}

      <div className="productDetails-flex gap-md-4 ">
        <div className="productDetails-flex productDetails-v-center productDetails-h-center productDetails-p-6-12 px-0 pb-0">
          <h5 className="productDetails-margin-h-6 price productDetails-txt-bold text-start">
            {(Number(calculatePrice()) * quantity).toFixed(2)}{" "}
            {Settings.currency}
          </h5>
        </div>
        <div className="productDetails-counter-button">
          <Button
            type="primary"
            shape="circle"
            size="middle"
            icon={<FaMinus size={"10px"} />}
            onClick={handleDecrement}
            className="productDetails-counter-control"
            disabled={quantity === 0}
          />
          <span className="productDetails-count">{quantity}</span>
          <Button
            type="primary"
            size="middle"
            shape="circle"
            icon={<FaPlus size={"10px"} />}
            onClick={handleIncrement}
            className="productDetails-counter-control"
          />
        </div>
      </div>
      {productInfo?.price_method == "Live" ? (
        <div className="productDetails-txt3 mt-0">
          Real time spot based pricing
        </div>
      ) : null}
      <p className="text-start mb-0">Inclusive of all taxes</p>
      <p className="shipping_information" >{`Shipping Information:`}</p>
      <p className="shipping_information"><span  className="text-secondary information_text">{`Delivery:    `}</span>Safe & Secure Delivery  </p>
      <p className="shipping_information"><span className="text-secondary information_text">{`Shipping:    `}</span>Free Shipping, Secure Delivery,7 Days Return,Certified Diamonds,Easy Exchange,Diamond Exchange  </p>
      <div className="productDetails-flex gap-2" style={{ marginTop: 10 }}>
        <Button
          type="primary"
          className="productDetails-cart-btn productDetails-margin-h-6 ms-0 rounded"
          size="large"
          onClick={() => {
            if (Auth.auth) {
              buyNow();
            } else {
              navigation("/login");
            }
          }}
        >
          Buy Now
        </Button>
        <Button
          type="default"
          className="productDetails-cart-btn productDetails-margin-h-6 me-0 rounded"
          icon={<BsHandbag />}
          size="large"
          onClick={() => {
            if (Auth.auth) {
              if (typeof addToCart === "function") {
                if (productInfo?.cart) {
                  navigation("/cart");
                } else {
                  addToCart(productInfo, quantity, calculatePrice());
                }
              }
            } else {
              navigation("/login");
            }
          }}
          loading={loadingCart}
        >
          {productInfo?.cart ? "View Cart" : " Add to Cart"}
        </Button>
      </div>
      <div
        className="productDetails-flex align-items-center"
        style={{ marginTop: 15 }}
      >
        <Button
          type="text"
          className="productDetails-text-btn1 ps-md-0"
          icon={
            productInfo?.wishlist ? (
              <FaHeart size={20} color="#d09e42" />
            ) : (
              <FaHeart size={20} color="#dbdbdb" />
            )
          }
        //   onClick={() => {
        //     if (typeof addWishlist == "function") addWishlist(productInfo);
        //   }}
        // >
        //   {!productInfo?.wishlist ? "Like" : "Like"}
        onClick={() => {
          if (Auth.auth) {
            if (typeof addWishlist === "function") {
              addWishlist(productInfo);
            }
          } else {
            navigation("/login");
          }
        }}
        >
          {!productInfo?.wishlist ? "Like" : "Liked"}
        
        </Button>
        <Button
          type="text"
          icon={<BiSolidShareAlt />}
          className="productDetails-text-btn1"
          onClick={shareLink}
        >
          Share
        </Button>{" "}
        {/* <span className="productDetails-secondary-text productDetails-margin-h-6 productDetails-text3 ">
          Any Issue Problem Product?
        </span> */}
        {/* <Button
          type="text"
          icon={<RiFlag2Fill />}
          className="productDetails-text-btn1 pe-md-0"
        >
          Report
        </Button> */}
      </div>
      <div
        className="productDetails-flex productDetails-space-between"
        style={{ marginTop: 15 }}
      ></div>
      <OrderBulkModal
        open={openModal}
        modalClose={() => toggleModal(false)}
        setQuantity={getQuantity}
      />
    </div>
  );
};

export default CartCard;
