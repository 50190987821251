import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input, notification } from "antd";
import { POST, PUT, COMPRESS_IMAGE, GET } from "../../utils/apiCalls";
import API from "../../config/API";
import TextArea from "antd/es/input/TextArea";
import ImagePicker from "../components/ImagePicker";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
const update = "update";
const CategoryUpdateModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>({});
  const type = props?.type;
  const fileInputRef = useRef(null);
  const { id }: any = props;

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [price, setPrice] = useState<any>({});
  useEffect(() => {
    if (type == update) {
      form.setFieldsValue({
        name: props?.data?.name,
        image: props?.data?.image,
        description: props?.data?.description,
      });
    } else {
      form.resetFields();
    }
  }, [props?.data, type]);

  useEffect(() => {
    getLiveRate();
  }, [props]);

  const getLiveRate = async () => {
    try {
      const url = API.LIVERATE_GET + `/${props.id}`;
      const rates: any = await GET(url, {});
      if (rates) {
        setPrice(rates);
        form.setFieldsValue({
          ask: rates?.ask,
          bid: rates?.bid,
        });
        console.log("rates", rates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmitHandler = async (values: any) => {
    setIsLoading(true);
    try {
      let obj: any = {
        ask: values.ask,
        bid: values.bid,
      };

      const responseimg: any = await PUT(`${API.LIVERATE_UPDATE}${id}`, obj);
      if (responseimg.statusCode == 200) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${type == update ? "Updated" : "Added"}`,
        });
        form.resetFields();
        props?.modalClose();
      } else {
        Notifications["error"]({
          message: `Failed to ${type == update ? "Update" : "Add New item"}`,
          description: responseimg.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: `Failed to ${type == update ? "Update" : "Add New item"}`,
        description: err.message,
      });
    }
    setIsLoading(false);
  };

  const validateNonNegative = (_: any, value: any) => {
    if (value <= 0) {
      return Promise.reject(new Error("Please enter a valid price!"));
    } else if ((value = "")) {
      return Promise.reject(new Error("Please enter a value"));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title={`Update LiveRate`}
      open={props?.open}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        props?.modalClose();
        setImage({});
        setCropModalOpen(false);
        form.resetFields()
      }}
    >
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
      >
        <Row>
          <Col md="9">
            <Form.Item
              label="Ask Rate"
              name="ask"
              rules={[
                {
                  required: true,
                  message: "Please Enter the rate",
                },
                {
                  validator: validateNonNegative,
                },
              ]}
            >
              <Input   type="tel" placeholder="Add/Remove" />
            </Form.Item>
          </Col>
          <Col md="3" className="ps-0">
            <Form.Item label="Add/Minus" name="askchange">
              <Input
                type="number"
                placeholder="Add/Minus"
                onChange={(event) => {
                  const add = Number(event?.target?.value);
                  const ask = Number(price?.ask);

                  if (typeof add == "number" && typeof ask == "number") {
                    const sum = ask + add;
                    form.setFieldsValue({ ask: sum });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md="9">
            <Form.Item
              label="Bid Rate"
              name="bid"
              rules={[
                {
                  required: true,
                  message: "Please Enter the rate",
                },
                {
                  validator: validateNonNegative,
                },
              ]}
            >
              <Input type="tel" placeholder="bid rate" />
            </Form.Item>
          </Col>
          <Col md="3" className="ps-0">
            {" "}
            <Form.Item label="Add/Minus" name="bidchange">
              <Input
                type="number"
                placeholder="Add/Minus"
                onChange={(event) => {
                  const add = Number(event?.target?.value);
                  const bid = Number(price?.bid);
                  if (typeof add == "number" && typeof bid == "number") {
                    const sum = bid + add;
                    form.setFieldsValue({ bid: sum });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {cropModalOpen ? null : (
          <div className="d-flex gap-2 justify-content-end">
            <Button
              onClick={() => {
                props?.modalClose();
                setImage({});
              }}
            >
              Cancel
            </Button>
            <Button type="primary" loading={isLoading} onClick={form.submit}>
              Update
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default CategoryUpdateModal;
