import React, { useEffect } from "react";
import "./styles.scss";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Button, Form, Select } from "antd";
import { RiTwitterXFill } from "react-icons/ri";
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function Careers() {
  const { t } = useTranslation();
  const { Option } = Select;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Container className="m-0 mw-100 p-0">
      <div className="Careers-container mx-0">
        <Row className="mx-0 mw-100">
          <Col md={8} />
          <Col md={4} xs={12} className="Careers-navBar px-2 px-md-0 mx-0">
            <FaFacebook
              onClick={() =>
                handleLinkClick("https://www.facebook.com/luckystargoldllc")
              }
              className="social-icon"
            />
            <RiTwitterXFill
              onClick={() =>
                handleLinkClick("https://twitter.com/Luckystargold")
              }
              className="social-icon"
            />
            <FaInstagram
              onClick={() =>
                handleLinkClick(
                  "https://www.instagram.com/luckystargoldgcc/?hl=en"
                )
              }
              className="social-icon"
            />
            <FaYoutube
              // onClick={() => handleLinkClick("")}
              className="social-icon"
            />
            <FaLinkedin
              // onClick={() => handleLinkClick("")}
              className="social-icon"
            />
          </Col>
        </Row>

        <Row className="mx-0">
          <Col md={3} />
          <Col md={6}>
            <div className="Careers-text-container text-white p-5  m-0 text-center ftscreen-fnt">
              <h5>{t("Find_the_career")}</h5>
              <h1 className="display-5">
              {t("We're_more_than")}
              </h1>
              <p className="fs-6">
              {t("We_know_that_finding")}
              </p>
              {/* <Button
                type="primary"
                size="large"
                className="rounded-pill px-5 mt-5 m-2"
                href=""
              >
                <strong>{t("View_Openings")}</strong>
              </Button> */}
            </div>
          </Col>
          <Col md={3} />
        </Row>
      </div>
      <div className="Screen-box">
        <div className="ftscreen-fnt m-2 ">
          <Row className="m-5 text-center">
            <h3>{t("Join_us_at")}{API.NAME}</h3>
            <p className="fs-6">{t("Current_Openings")}</p>
          </Row>
          <Row className="align-items-right">
            <Col xs={6} md={10}></Col>
            <Col xs={6} md={2}>
              <Form layout="inline">
                <Form.Item label={t("Filter_By")} name="filter" className="mr-2">
                  <Select placeholder={t("Select")} style={{ width: "100px" }}>
                    <Option value="all">{t("All")}</Option>
                    <Option value="fulltime">{t("Full_Time")}</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <hr />
          <Row>
            <Row gutter={16}>
              <Col span={8}>
                <Card title="Card title">{t("Card_content")}</Card>
              </Col>
              <Col span={8}>
                <Card title="Card title">{t("Card_content")}</Card>
              </Col>
              <Col span={8}>
                <Card title="Card title">{t("Card_content")}</Card>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default Careers;
