import React, { useEffect } from "react";
import PageHeader from "../../components/pageHeader";
import SaleMessage from "../../components/saleMessage";
import API from "../../config/API";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text="Privacy Policy"></PageHeader>
        <h4 className="mt-4">Privacy Policy</h4>
        <div>
          At {API.NAME}, we value your trust and are committed to maintaining
          the privacy and security of your personal information. This Privacy
          Policy outlines how we collect, use, and safeguard the information you
          provide to us when you visit our website.
        </div>
        <h4 className="mt-4">Changes to the Privacy Policy</h4>
        <div>
          Please note that this Privacy Policy may be subject to changes and
          updates without prior notice. We encourage you to review this policy
          periodically to stay informed about how we protect your privacy.
        </div>
        <h4 className="mt-4">Collection of Personal Information:</h4>
        <div>
          When you create an account with {API.WEBSITE}, we collect personal
          information such as your name, email address, contact number, and
          other relevant details only with the consent of the customer. This
          information is used for setting up your account with your consent and
          enabling you to place orders. Additionally, we may use your contact
          details to notify about exclusive offers based on your previous orders
          and interests.
        </div>
        <h4 className="mt-4">Use of Information:</h4>
        <div>
          We use the personal information you provide to fulfill your service
          requests. This includes troubleshooting problems, conducting surveys,
          and delivering information about our latest offers. We may also
          analyze demographic and profile data to better understand user
          activity on our website. Furthermore, we utilize IP addresses to
          diagnose any technical issues that may arise.
        </div>
        <h4 className="mt-4">Disclosure of Personal Data:</h4>
        <div className="my-2">
          a. To enhance and facilitate our services, we may engage the services
          of third-party entities and individuals. These parties may assist us
          with tasks such as maintenance, analysis, audit, marketing, and
          development. Rest assured, these third parties are granted limited
          access to your information solely for the purpose of carrying out
          these tasks on behalf of {API.NAME}. We ensure that they adhere to
          strict confidentiality terms and comply with data protection
          regulations.
        </div>
        <div className="my-2">
          b. When using our application, you will be required to acknowledge and
          accept the third-party developer user agreement as well as the{" "}
          {API.NAME} privacy policy.
        </div>
        <div className="my-2">
          c. There may be circumstances where we need to disclose your
          information to other parties in order to comply with legal
          requirements, respond to mandatory legal processes, verify, or enforce
          compliance with our service policies, or safeguard the rights,
          property, or safety of {API.NAME}, our affiliates, business partners,
          or customers. These disclosures are made in accordance with applicable
          laws and regulations, with the utmost priority given to protecting
          your privacy.
        </div>
        <h4 className="mt-4">Security Precautions:</h4>
        <div>
          We have implemented strict security measures to protect your
          information from loss, alteration, and misuse. Our website utilizes
          secure servers to store and safeguard your personal account
          information. Once your information is in our possession, we adhere to
          stringent security guidelines to prevent unauthorized access.
        </div>
        <h4 className="mt-4">Choice/Opt-Out:</h4>
        <div>
          {API.WEBSITE} provides users with the option to opt-out of promotional
          and marketing-related services from us and our partners after setting
          up an account. You can manage your communication preferences by
          accessing your account settings.
        </div>
      </div>
      <h4 className="mt-4">Links to Other Websites:</h4>
      <div>
        Our website may contain links to external websites. Please be aware that
        {API.NAME} is not responsible for the privacy practices or content of
        these third-party websites. When you access these external platforms,
        their respective privacy policies and terms of use will apply. We
        recommend reviewing the policies of any website or application you visit
        to understand your rights and obligations. If you have any concerns or
        questions, please contact the owner or operator of the specific website
        or application. At {API.NAME}, we prioritize the protection of your
        privacy. If you have any concerns or questions regarding the handling of
        your personal data, please don't hesitate to contact us at{" "}
        {API.CONTACT_MAIL}.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
