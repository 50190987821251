import { Col, Row } from "antd";
import React, { useContext } from "react";
import CartCard from "../cartCard";
import VariantTypes from "../variants";
import { SingleProductContext } from "../../singleProductContext";

function capitalize(inputString: string) {
  if (!inputString) {
    return "";
  } else {
    return inputString
      ?.split(" ")
      ?.map(
        (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  }
}
const ProductDescription = (data: any) => {
  const context = useContext(SingleProductContext);
  const productInfo = context?.data;
  return (
    <>
      {/* <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 ">
          <span className="productDetails-text2 text-secondary">Category:</span>{" "}
          <span className="fw-bold">
            {capitalize(data?.data?.categoryName?.name)}/
            {capitalize(data?.data?.subCategoryName?.name)}
          </span>
        </h3>
      </div> */}
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 ">
          <span className="productDetails-text2 text-secondary">
            Metal Purity:
          </span>{" "}
          <span className="fw-bold">{capitalize(data?.data?.purity)}</span>
        </h3>
      </div>
      <div className="productDetails-flex productDetails-flex-row"></div>
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 ">
          <span className="productDetails-text2 text-secondary">Weight:</span>{" "}
          <span className="fw-bold">{data?.data?.weight} Gram</span>
        </h3>
      </div>
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 ">
          <span className="productDetails-text2 text-secondary">Category:</span>{" "}
          <span className="fw-bold">{data?.data?.categoryName?.name}</span>
        </h3>
      </div>
      <Row className="mx-0">
        <Col className="col-12 col-md-8">
          <CartCard />
        </Col>
        <Col className="col-12">
          <VariantTypes />
        </Col>
        <Col md="6"></Col>
      </Row>
      <p className="mb-0 fw-medium">
        <span className="text-secondary">ProductCode:</span> {productInfo?.sku}
      </p>
    </>
  );
};
export default ProductDescription;
