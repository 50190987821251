// import React, { useState } from "react";
// import Slider from "react-slick";
// function Banners(props: any) {
//   const [current, setCurrentSlideNumber] = useState(0);
//   const Slidesettings = {
//     dots: true,
//     infinite: true,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     speed: 3000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     swipeToSlide: true,
//     appendDots: (dots: any) => (
//       <div style={{
//         backgroundColor: "#FFF",
//         padding: "10px",
//       }}>
//         <ul style={{ margin: "0px" }}>{dots}</ul>
//       </div>
//     ),
//     customPaging: (i: any) => (
//       <div className={i === current ? "square active" : "square"}></div>
//     )
//   };
//   console.log(props.data, "props.dataprops.dataprops.dataprops.dataprops.data");

//   return (
//     <div className="HomeScreen-BannerBox">
//       {props?.data?.length ? (
//         <Slider {...Slidesettings} beforeChange={(currentSlide: number, nextSlide: number) => setCurrentSlideNumber(nextSlide)}>
//           {props.data.map((bann: any) => {
//             return (
//               <div key={bann.id}>
//                 <div
//                   key={bann.id}
//                   className="HomeScreen-Banners"
//                   style={{ backgroundImage: `url(${bann.img_desk})` }}
//                 >
//                   <div className="HomeScreen-BannersBox">
//                     <div className="HomeScreen-BannersBox2">
//                       <div className="HomeScreen-Bannertxt2">{bann.title}</div>
//                       <div className="HomeScreen-Bannertxt3">
//                         {bann.description}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </Slider>
//       ) : null}
//     </div>
//   );
// }
// export default Banners;
import React, { useState, useEffect } from "react";
import Slider from "react-slick";

function Banners(props: any) {
  const [current, setCurrentSlideNumber] = useState(0);
  const [sortedData, setSortedData] = useState<any[]>([]);

  useEffect(() => {
    // Sorting the banners by position
    const sorted = props?.data?.length
      ? [...props.data].sort((a: any, b: any) => a.position - b.position)
      : [];
    setSortedData(sorted);
  }, [props.data]);

  const Slidesettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000, // Time between slides
    speed: 4000, // Slide transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0, // Start from the first slide
    appendDots: (dots: any) => (
      <div
        style={{
          backgroundColor: "#FFF",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div className={i === current ? "square active" : "square"}></div>
    ),
    beforeChange: (currentSlide: number, nextSlide: number) =>
      setCurrentSlideNumber(nextSlide),
  };

  return (
    <div className="HomeScreen-BannerBox">
      {sortedData.length ? (
        <Slider {...Slidesettings}>
          {sortedData.map((bann: any) => (
            <div key={bann.id}>
              <div
                className="HomeScreen-Banners"
                style={{ backgroundImage: `url(${bann.img_desk})` }}
              >
                <div className="HomeScreen-BannersBox">
                  <div className="HomeScreen-BannersBox2">
                    <div className="HomeScreen-Bannertxt2">{bann.title}</div>
                    <div className="HomeScreen-Bannertxt3">
                      {bann.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
}

export default Banners;
