
import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./styles.scss";
import { IoIosArrowForward, IoIosContact } from "react-icons/io";
import { Button, Form, Input, Modal, Select, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { IoCall, IoLocationOutline } from "react-icons/io5";
import { LuClock1 } from "react-icons/lu";
import { RiWhatsappFill } from "react-icons/ri";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";

function ContactUs() {
    const iframeHTML = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092906!2d144.95373511568046!3d-37.81627974261715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43c12a6e17%3A0x5045675218ce6e0!2sFederation+Square!5e0!3m2!1sen!2sau!4v1510923215945" width="100%" height="300" style="border:0;border-radius:10px;"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

  return (
    <>
   
<div className="LayoutBox">
  <br />
  <Container>
    <Row>
     
      <Col sm={{ span: 10, offset: 1 }} xs={12}>
        <h3 className="heading_h3">Contact Us</h3>

        <Row>
          <Col sm={7} xs={12}>
          <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />

         


{/* testr */}



<div className="ContactUs_Box2">
              <div className="round-icon-container">
              <MdOutlineMailOutline size={30} color="#575c5e" />
              </div>
              <div>
                <div className="ContactUs_text1">sales@luckystargold.com</div>
             
              </div>
            </div>


            
            <div className="ContactUs_Box1">
              <div className="round-icon-container">
                <IoLocationOutline size={30} color="#575c5e" />
              </div>
              <div>
                <div className="ContactUs_text1">Address</div>
                <div className="ContactUs_text2">
                LSG Digital Gold,
Dalmook 3 Building Shop No5,

               
                  <br /> 
                  Near Women's Museum Gold Souk,
Dubai, UAE
                </div>
              </div>
            </div>
            <div className="ContactUs_Box1">
              <div className="round-icon-container">
                <IoIosContact size={30} color="#575c5e" />
              </div>
              <div>
                <div className="ContactUs_text1">Contact</div>
                <div className="ContactUs_text2  my-2" >
                  <IoCall size={28} color="#3f8ad4" />
                  &nbsp;+971 54 581 6161
                </div>
                <div
                  className="ContactUs_text2  my-2"
                >
                  <RiWhatsappFill size={30} color="#129922" />
                  &nbsp;+971 54 581 6161
                </div>
              </div>
            </div>
          </Col>
          <Col sm={5} xs={12}>
            <Form >
              
              <div className="ContactUs_text3">Name</div>
              <FormItem name="name">
                <Input type="text" placeholder="Name" size="large" />
              </FormItem>
              <div className="ContactUs_text3">Email</div>
              <FormItem
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input type="email" placeholder="Email" size="large" />
              </FormItem>
              <div className="ContactUs_text3">Phone</div>
              <FormItem name="phone">
                <Input type="number" placeholder="Phone" size="large" />
              </FormItem>
              <div className="ContactUs_text3">Message</div>
              <FormItem name="message">
                <TextArea
                  rows={4}
                  placeholder="Write anything here..."
                  size="large"
                />
              </FormItem>
              <Col sm={{ offset: 6, span: 6 }} xs={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="contactSubmit_Button"
                >
                  Submit
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
  <br />
</div>
</>

  )
}

export default ContactUs

