import { Badge, Pagination, Popconfirm, Table, notification } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSolidEditAlt } from "react-icons/bi";
import API from "../../config/API";
import { DELETE } from "../../utils/apiCalls";
import "./styles.scss";
import React from "react";
import { FaEye, FaList } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
function DataTable(props: any) {
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();
  const itemDeleteHandler = async (item: any) => {
    const url = API.CATEGORY + item?.id;
    try {
      const response: any = await DELETE(url);
      if (response.status) {
        Notifications["success"]({
          message: "Success",
          description: response.message,
        });
        props?.getCategory(props?.page);
      } else {
        Notifications["error"]({
          message: "Failed to Delete",
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed to Delete",
        description: err.message,
      });
    }
  };
  const columns = [
    {
      title: "",
      dataIndex: "position",
      key: "position",
      render: (position: any, _record: any) => (
        <Badge count={position} showZero size="small" color="orange">
          <FaList
            cursor="pointer"
            size={20}
            color={"grey"}
            onClick={() => {
              props?.openPosition();
              props?.setPosition(_record);
            }}
          />
        </Badge>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      render: (TEXT: any, _record: any) => <div>{TEXT}</div>,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (TEXT: any, _record: any) => <div>{TEXT}</div>,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      render: (TEXT: any, _record: any) => <div>{TEXT}</div>,
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (TEXT: any, _record: any) => <div>{TEXT}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_text: any, _record: any) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {contextHolder}
          <FaEye
            cursor="pointer"
            size={22}
            color={"black"}
            onClick={() =>
              navigate(`/auth/CustomProduct/Details/${_record?.id}`)
            }
          />
          &nbsp;
          {/* <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => itemDeleteHandler(_record)}
          >
            <RiDeleteBin6Line cursor="pointer" size={22} color={"red"} />
          </Popconfirm> */}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data?.length ? props.data : []}
        columns={columns}
        pagination={false}
      />
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={props?.page || 1}
          pageSize={props?.pageSize || 10}
          total={props?.meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={props?.pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            props?.changePage(current, size);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
