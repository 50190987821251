import { BsShopWindow } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { Button, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import React from "react";
import API from "../../config/API";
import { MdOutlineSell } from "react-icons/md";
import SellusModal from "../sellusModal";

function CateogreyList() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const Settings = useSelector((state: any) => state?.Settings?.Settings);
  const Auth = useSelector((state: any) => state?.User);
  const User = useSelector((state: any) => state?.User?.user);
  const Category = useSelector((state: any) => state?.Category?.categries);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [item, setItem] = useState();
  const [openStates, setOpenStates] = useState(
    Array.isArray(Category) ? Category.map((item: any) => false) : [false]
  );

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const OpenLink = () => {
    if (Auth?.auth) {
      switch (User?.data?.type) {
        case "user":
          navigation("/seller");
          break;
        default:
          navigation(`/auth/check_store`);
          break;
      }
    } else {
      navigation("/seller");
    }
  };

  return (
    <>
      <div className={`Header-deskCateogrey  ${!show ? "d-none" : ""}`}>
        {Settings?.type === "multi" || User?.data?.type === "admin" ? (
          <div
            style={{ paddingRight: 10, paddingLeft: 5 }}
            className={`Header-deskCatItem ${User?.data?.type !== "user" && Auth.auth ? "active" : ""
              }`}
            onClick={() => OpenLink()}
          >
            <div style={{ marginRight: 20, marginBottom: 5 }}>
              <BsShopWindow size={20} />
            </div>
            <div>
              {Auth.auth && User?.data?.type !== "user"
                ? "Manage Store"
                : "Become a seller"}
            </div>
          </div>
        ) : null}
        <div className="Header-deskcat-2">
          {/* {Category && Category.length
            ? Category.map((item: any, index: number) => {
              return (
                <Popover
                  key={index}
                  arrow={false}
                  placement="bottomRight"
                  open={openStates?.[index]}
                  onOpenChange={() => {
                    setOpenStates((state: boolean[]) => {
                      return state?.map((value, i) =>
                        i === index ? !value : value
                      );
                    });
                  }}
                  content={
                    <div style={{ width: "50vw" }}>
                      <Row>
                        <Col style={{ borderLeft: "solid 1px gray" }} sm={8}>
                          <div className="Header-deskCatItemtxt1">
                            {item?.name}
                          </div>
                          <div className="Header-deskCatItemline" />
                          <Row>
                            {item?.sub_categories?.length &&
                              item.sub_categories.map(
                                (sub: any, key: number) => (
                                  <Col key={key} sm={6}>
                                    <div
                                      className="Header-deskCatItem3"
                                      onClick={() => {
                                        navigation(
                                          `/products/category?id=${window.btoa(
                                            sub._id
                                          )}&type=${sub.name}`,
                                          {
                                            state: {
                                              sub,
                                            },
                                          }
                                        );

                                        setOpenStates((state: boolean[]) =>
                                          state?.map(() => false)
                                        );
                                      }}
                                    >
                                      {sub.name}
                                    </div>
                                    {sub?.types?.map(
                                      (val: any, index: any) => (
                                        <div
                                          onClick={() => {
                                            navigation(
                                              `/products/category?id=${window.btoa(
                                                sub._id
                                              )}&tid=${val?._id}&type=${sub?.name
                                              }`,
                                              {
                                                state: {
                                                  val,
                                                },
                                              }
                                            );

                                            setOpenStates(
                                              (state: boolean[]) =>
                                                state?.map(() => false)
                                            );
                                          }}
                                          key={index}
                                          className="Header-deskTypeitem"
                                        >
                                          <img
                                            src={val?.image}
                                            className="Header-deskTypeimg"
                                          />
                                          {val?.name}
                                        </div>
                                      )
                                    )}
                                  </Col>
                                )
                              )}
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <div className="Header-deskCatItemBox">
                            <img
                              src={item?.image}
                              className="Header-deskCatItemImg"
                              alt=""
                            />
                            <br />
                            <div className="Header-deskCatItemtxt2">
                              {item?.description}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <div className="Header-deskCatItem">
                    {item && item.name}
                  </div>
                </Popover>
              );
            })
            : "qwertyui"} */}
          {Category && Category.length > 0 ? (
            Category.map((item: any, index: number) => {
              return (
                <Popover
                  key={index}
                  arrow={false}
                  placement="bottomRight"
                  open={openStates?.[index]}
                  onOpenChange={() => {
                    setOpenStates((state: boolean[]) => {
                      return state?.map((value, i) => (i === index ? !value : value));
                    });
                  }}
                  content={
                    <div style={{ width: "50vw" }}>
                      <Row>
                        <Col style={{ borderLeft: "solid 1px gray" }} sm={8}>
                          <div className="Header-deskCatItemtxt1">{item?.name}</div>
                          <div className="Header-deskCatItemline" />
                          <Row>
                            {item?.sub_categories?.length
                              ? item.sub_categories.map((sub: any, key: number) => (
                                <Col key={key} sm={6}>
                                  <div
                                    className="Header-deskCatItem3"
                                    onClick={() => {
                                      navigation(
                                        `/products/category?id=${window.btoa(
                                          sub._id
                                        )}&type=${sub.name}`,
                                        {
                                          state: {
                                            sub,
                                          },
                                        }
                                      );

                                      setOpenStates((state: boolean[]) =>
                                        state?.map(() => false)
                                      );
                                    }}
                                  >
                                    {sub.name}
                                  </div>
                                  {sub?.types?.map((val: any, index: any) => (
                                    <div
                                      onClick={() => {
                                        navigation(
                                          `/products/category?id=${window.btoa(
                                            sub._id
                                          )}&tid=${val?._id}&type=${sub?.name}`,
                                          {
                                            state: {
                                              val,
                                            },
                                          }
                                        );

                                        setOpenStates((state: boolean[]) =>
                                          state?.map(() => false)
                                        );
                                      }}
                                      key={index}
                                      className="Header-deskTypeitem"
                                    >
                                      <img
                                        src={val?.image}
                                        className="Header-deskTypeimg"
                                      />
                                      {val?.name}
                                    </div>
                                  ))}
                                </Col>
                              ))
                              : null}
                          </Row>
                        </Col>
                        <Col sm={4}>
                          <div className="Header-deskCatItemBox">
                            <img
                              src={item?.image}
                              className="Header-deskCatItemImg"
                              alt=""
                            />
                            <br />
                            <div className="Header-deskCatItemtxt2">
                              {item?.description}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <div className="Header-deskCatItem">{item && item.name}</div>
                </Popover>
              );
            })
          ) : (
            <div className=""></div> // Add this to display when Category is empty or undefined
          )}





        </div>
        {Settings?.type !== "multi" ? (
          <div className="Header-deskcat-2">
            <div className="Header-deskCatItem2 d-flex gap-2 align-items-center">
              <span
                className="Header-desk-menu py-1"
                onClick={() => setOpenForm(true)}
              >
                <MdOutlineSell size={18} />
                &nbsp; Sell to US
              </span>
              <span
                style={{
                  whiteSpace: "nowrap",
                  display: "inline-flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  window.open(`https://wa.me/${API.CONTACT_NUMBER}`, "_blank")
                }
              >
                <IoCallOutline size={18} />
                &nbsp;
                {API.CONTACT_NUMBER}
              </span>
              &nbsp;
            </div>
          </div>
        ) : null}
      </div>
      {openForm ? (
        <SellusModal onClose={() => setOpenForm(false)} visible={openForm} />
      ) : null}
    </>
  );
}
export default CateogreyList;
