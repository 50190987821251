import API from "../../config/API";
import { useEffect, useState } from "react";
import AdminLoading from "../components/AdminLoading";
import { Button, notification } from "antd";
import PageHeader from "../components/PageHeader";
import useToggle from "../../shared/hook/useToggle";
import React from "react";
import Search from "antd/es/input/Search";
import { IoSearchOutline } from "react-icons/io5";
import { GET } from "../../utils/apiCalls";
import DataTable from "./dataTable";
type ModalState = "add" | "update";
function CustomProduct() {
  const [Notifications, contextHolder] = notification.useNotification();
  const [toggle, toggleModal] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [type, setType] = useState<ModalState>("add");
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const pageSize = 10;
  const [positionModal, togglePosition] = useToggle(false);
  const [positionSelected, setPositionSelected] = useState<any>({});

  const getCategories = async (
    currentPage: number = 1,
    search: string = ""
  ) => {
    setLoading(true);
    try {
      const url =
        API.CUSTOMIZED_ORDER_ALL +
        `?order=ASC&page=${currentPage}&take=${pageSize}&search=${search}`;
      const data: any = await GET(url, null);
      if (data?.status) {
        setCategory(data?.data);
        setMeta(data?.meta);
      } else {
        Notifications["error"]({
          message: data?.message ?? "",
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };
  const changePage = async (page: number, take: number) => {
    await getCategories(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setSearching(true);
      await getCategories(1, value);
      setPage(1);
      setSearching(false);
    } else {
      getCategories();
      setPage(1);
      setSearch("");
    }
  };
  return (
    <>
      <div className="">
        {contextHolder}
        <PageHeader
          title={`My Design Orders ${
            loading ? "" : `(${meta?.itemCount ?? 0})`
          }`}
        >
          <Search
            placeholder="Search Orders"
            allowClear
            enterButton={<IoSearchOutline />}
            size="middle"
            onSearch={onSearch}
            loading={searching}
          />
        </PageHeader>
        {loading ? (
          <AdminLoading />
        ) : (
          <DataTable
            data={category}
            getCategory={getCategories}
            openModal={() => toggleModal(true)}
            getSelectedItem={receiveSelectedItem}
            changeType={() => setType("update")}
            page={page}
            pageSize={pageSize}
            changePage={changePage}
            meta={meta}
            openPosition={() => togglePosition(true)}
            setPosition={setPositionSelected}
          />
        )}
      </div>
    </>
  );
}
export default CustomProduct;
