import React, { useEffect, useState } from "react";
import DataTable from "./dataTable";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import Loading from "../../components/loading";
import BannerUpdateModal from "./bannerModal";
import PageHeader from "../components/PageHeader";
import { Button, notification } from "antd";
import useToggle from "../../shared/hook/useToggle";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";
import UpdatePostionModal from "./updatePositionModal";

type NotificationType = "success" | "info" | "warning" | "error";
function Banners() {
  useEffect(() => {
    getBannerData();
  }, []);

  type ModalState = "add" | "update";
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [type, setType] = useState<ModalState>("add");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [reset, setReset] = useState(false);
  const [meta, setMeta] = useState<any>({});
  const [toggle, toggleModal] = useToggle(false);
  const [search, setSearch] = useState("");
  const [notificationApi, contextHolder] = notification.useNotification();
  const [searching, setSearching] = useState(false);
  const [positionModal, togglePosition] = useToggle(false);
  const [positionSelected, setPositionSelected] = useState<any>({});
  const getBannerData = async (page: number = 1, search: string = "") => {
    try {
      let url =
        API.BANNER_ALL +
        `?order=DESC&page=${page}&take=${pageSize}&search=${search}`;
      let response: any = await GET(url, null);
      if (response.status) {
        setBannerData(response.data);
        setMeta(response?.meta);
      } else {
        notificationApi["error"]({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi["error"]({ message: "Failed to get Banners" });
    } finally {
      setLoading(false);
    }
  };

  const receiveSelectedItem = (item: any) => {
    setSelectedItem(item);
  };

  const changePage = async (page: number, take: number) => {
    await getBannerData(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getBannerData(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  return (
    <div>
      <PageHeader
        title={`Banners ${loading ? "" : `(${meta?.itemCount ?? 0})`}`}
      >
        {contextHolder}
        <Button
          onClick={() => {
            toggleModal(true);
            setType("add");
          }}
          type="primary"
        >
          Add Banners +
        </Button>
        {/* <Search
          placeholder="Search Banner Title"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        /> */}
        {reset ? (
          <Button
            type="primary"
            loading={loading}
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getBannerData();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
      </PageHeader>
      {loading && loading ? (
        <Loading />
      ) : (
        <DataTable
          data={bannerData}
          getBannerData={getBannerData}
          getBanner={getBannerData}
          closeModal={() => toggleModal(false)}
          openModal={() => toggleModal(true)}
          getSelectedItem={receiveSelectedItem}
          changeType={() => setType("update")}
          page={page}
          pageSize={pageSize}
          meta={meta}
          openPosition={() => togglePosition(true)}
          changePage={changePage}
          setPosition={setPositionSelected}
        />
      )}
      <BannerUpdateModal
        open={toggle}
        modalClose={() => {
          toggleModal(false);
        }}
        data={selectedItem}
        getBanner={getBannerData}
        type={type}
        page={page}
      />
      <UpdatePostionModal
        open={positionModal}
        close={() => togglePosition(false)}
        data={positionSelected}
        refresh={() => getBannerData(page, search)}
      />
    </div>
  );
}
export default Banners;
