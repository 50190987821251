import React, { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import API from "../../config/API";
import PageHeader from "../../components/pageHeader";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt mx-2">
        <PageHeader text="Refund Policy"></PageHeader>
        <h4 className="mt-4">Refund Policy</h4>
        <div className="pb-2" style={{ textAlign: "justify" }}>
          At {API.NAME}, we strive to ensure customer satisfaction with our
          products. If for any reason you are not completely satisfied with your
          purchase made on {API.WEBSITE} we offer a refund policy.
          <br />
          <br /> Please note this refund policy is not applicable for purchases
          made at any of our retail stores, or any other online marketplace.
          <br />
          <br /> Please note below mentioned refund policy terms and conditions:
          <br />
          <br />
          <h4>Return Period:</h4>
          <div className="pb-2" style={{ textAlign: "justify" }}>
            Customers have a period of 14 days from the day of delivery to
            initiate a return of our products. During this time, you can decide
            whether to keep the product or return it for a refund
          </div>
          The refund policy is subject to the following conditions:
        </div>
        <ul>
          <li>The return request must be made within 14 days of delivery.</li>
          <li>
            The product must be returned to its original condition, with all
            accompanying paperwork, including the insurance certificate, initial
            invoice, and product certificate.
          </li>
          <li>
            The refund facility is not applicable to gold coins, silver
            articles, Gold rakhi, watches and products ordered with specific
            indications such as Smart Buy (Make to Order), customization,
            engraving, personalized items, gift cards, purchases made using
            promotion codes, or with discount coupons.
          </li>
          <li>
            Please note that this refund facility is available only to purchases
            made through our registered official website, {API.WEBSITE} and is
            not applicable to purchases made at other physical stores in India.
          </li>
        </ul>
        <h4 className="mt-4">Return Process:</h4>
        <ul>
          <li>
            To initiate a return, please email us at {API.CONTACT_MAIL} Our
            customer support team will guide you through the return process.
          </li>
          <li>
            Upon contacting us, we will provide you with a "Return Packaging
            Kit" to assist you in securely packing the product for return. We
            request that you create a video of the product being packed and send
            it to us via WhatsApp with your order number at {API.CONTACT_NUMBER}
            . This video serves as a confirmation of the product's condition
            prior to return.
          </li>
          <li>
            Once you have packed the product and obtained the necessary
            documentation, we will arrange for a courier to pick up the package.
            Please make a note of the courier's airway bill number for
            reference.
          </li>
          <li>
            Please note that the entire backward pick-up process may take
            approximately 7 to 10 working days to complete.
          </li>
          <li>
            If we're unable to secure a courier partner for the return,
            customers are required to personally send the product via their
            preferred courier service. Customers must guarantee that the chosen
            courier is insured and that all courier fees are covered by them.
            Upon receiving the package, we will conduct a quality check (QC) on
            the product. If the QC passes, we'll proceed with refunding the
            product's cost and the courier charges paid by the customer. If the
            QC doesn't pass, we'll notify the customer, who will then need to
            process a claim for the product through their selected courier
            partner.
          </li>
        </ul>
        <h4 className="mt-4">Refund Processing:</h4>
        <ul>
          <li>
            Upon receiving the returned product at our facility and verifying
            its condition by our skilled quality inspection team, {API.NAME}{" "}
            will initiate the refund process.
          </li>
          <li>
            The amount will be refunded to your account within 10 business days
            from the date of receiving the returned product as per the terms and
            conditions mentioned in the return policy. The refund will be
            processed to the customer account through which the payment of the
            order was initiated. Please note that the amount will not be
            refunded to any other bank account
          </li>
          <li>
            Customized Jewellery cannot be replaced and Jewellery damaged due to
            negligence in handling can only be repaired. No Cash refund is
            admissible
          </li>
          <li>
            We value your trust in {API.NAME}, and we strive to provide a
            seamless return/refund experience. If you have any further questions
            or require assistance, please contact our customer support team at{" "}
            {API.CONTACT_NUMBER}.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RefundPolicy;
