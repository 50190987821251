import React, { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";

const CancellationPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="Screen-box">
        <div>
          <SaleMessage />
        </div>
        <div className="ftscreen-fnt mx-2">
          <PageHeader text="Cancellation Policy"></PageHeader>
          <h4 className="mt-4">Cancellation Policy</h4>
          <div>
            At {API.NAME}, we strive to provide a seamless shopping experience
            for our customers. However, under certain circumstances, order
            cancellations may be necessary. Please refer to our detailed
            cancellation policy outlined below: Cancellation by
            {API.NAME}:
          </div>
          <div className="my-3">
            We reserve the right to cancel any order in the event of a
            discrepancy. The common causes for cancellation from our end
            include, but are not limited to:
          </div>
          <ul>
            <li>Limited inventory of the product</li>
            <li>Pricing errors or product information errors</li>
            <li>Quality rejection during inspection</li>
            <li>
              Insufficient customer information or unsuccessful verification
            </li>
            <li>Suspicious orders or fraudulent activities</li>
            <li>Natural calamities or unforeseen circumstances</li>
          </ul>
          <div className="my-2">
            We may also request additional details from the customer either
            during or before accepting an order to ensure accuracy and security.
          </div>
          <h4 className="mt-4">Ready-to-Ship Orders:</h4>
          <div>
            Customers can cancel a ready-to-ship order before it is shipped. If
            a cancellation request is received for such an order, we will refund
            the amount using the original payment method within 10 business
            days. Please note that a pre-dispatch cancellation fee of 2% will be
            applied, and the remaining 98% will be credited back to the
            customer's account (To the same source of account from which payment
            was made by customer to purchase the product). It's important to
            note that gold coins, bars, Rakhi and solitaire orders cannot be
            cancelled under any circumstances.
          </div>
          <h4 className="mt-4">Make-to-Order:</h4>
          <div>
            Smart Buy (custom-made), personalized, or customized jewelry items
            undergo a specialized manufacturing process and are created only
            upon customer orders. If a customer decides to cancel their order
            within 2 days of purchase, the entire order value will be refunded
            to the customer's account in accordance with our refund policy terms
            (To the same source of account from which payment was made by
            customer to purchase the product). However, if a customer chooses to
            cancel the order after 2 days from the date of purchase, whether
            before or after dispatch, the refund for the cancellation of Smart
            Buy (custom-made), personalized, or customized jewelry will be
            processed after deducting the making charges and stone charges
            associated with the canceled jewelry. The remaining amount will be
            refunded to the customer's account (To the same source of account
            from which payment was made by customer to purchase the product)
            within 10 business days or can be adjusted towards an exchanged
            product(s)
          </div>
          <h4 className="mt-4">Failed Transactions:</h4>
          <div>
            If a transaction fails and the amount is debited from the customer's
            account, it will be returned to the respective account within 72
            hours by the payment gateway used.
          </div>
          <div className="my-2">
            Please note that the cancellation policy may vary for specific
            products or during promotional periods. The terms and conditions of
            the cancellation policy are subject to change at the discretion of
            {API.NAME}.
          </div>
          <div className="mb-2">
            For any further clarification or assistance regarding our
            cancellation policy, please contact our customer support team at{" "}
            {API.CONTACT_MAIL}
          </div>
          <div>
            Disclaimer: Please be aware that this cancellation policy is
            specific to orders placed on {API.WEBSITE}. Different policies may
            be in effect for purchases made through other channels. We recommend
            contacting the respective channel for a detailed policy.
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
